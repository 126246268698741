import React from 'react'
import Badge from 'react-bootstrap/Badge'

import ReferencedRow from '../../styles/referenced-row'
import { formatDateRange } from '../shared/date-utils'

const { Row, Reference, Entry } = ReferencedRow('36px')

export const EventRow: React.FC<any> = ({ event }) => {
  const ref = event?.reference
  if (ref) {
    return (
      <Row>
        <Reference>{ref}.</Reference>
        <Entry>
          <Event event={event} />
        </Entry>
      </Row>
    )
  } else {
    return null
  }
}

const Event: React.FC<any> = ({ event }) => {
  const { name, url, startDate, endDate, locationName, online, canceled } =
    event
  return (
    <>
      {url ? <a href={url}>{name}</a> : name},{' '}
      {formatDateRange(startDate, endDate)}
      {locationName ? `, ${locationName}` : null}
      {online === true ? (
        <>
          {' '}
          <Badge pill bg="success">
            virtual
          </Badge>
        </>
      ) : null}
      {canceled === true ? (
        <>
          {' '}
          <Badge pill bg="warning" text="dark">
            canceled
          </Badge>
        </>
      ) : null}
    </>
  )
}

export default Event
