import React from 'react'
import { createContext, useState } from 'react'

interface EventContextInterface {
  getReference(event: string): number
}

export const EventContext = createContext<EventContextInterface>({
  getReference: () => 0,
})

export const EventProvider: React.FC<any> = ({ children }) => {
  const [eventMap, setEventMap] = useState(new Map<string, number>())
  return (
    <EventContext.Provider
      value={{
        getReference: (event) => {
          let ref = eventMap.get(event)
          if (ref === undefined) {
            const newRef = eventMap.size + 1
            eventMap.set(event, newRef)
            ref = newRef
          }
          return ref
        },
      }}
    >
      {children}
    </EventContext.Provider>
  )
}
