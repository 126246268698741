import React from 'react'
import { Link } from 'gatsby'

import { Heading } from '../layout/tableofcontent/heading'
import { Course } from '../course'
import coursePath from '../shared/course-path'

const ContentCourseList = ({ content: { courses, headingLevel } }) => {
  return courses.map((course) => {
    const link = coursePath(course)
    return (
      <>
        {headingLevel ? (
          <Link to={link}>
            <Heading level={headingLevel} style={{ display: 'inline-block' }}>
              {course.name}
            </Heading>
          </Link>
        ) : null}
        <Course course={course} />
      </>
    )
  })
}

export default ContentCourseList
