import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ContentRenderer from '../components/content/content-renderer'
import { EventProvider } from '../components/event/event-provider'

const Page = ({ data }) => {
  const { content, headerImage, toc, strapi_id, updatedAt, __typename } =
    data.strapiPage

  return (
    <Layout
      headerImage={headerImage}
      tocEnabled={toc}
      objectInfo={{ strapi_id, updatedAt, __typename }}
    >
      <EventProvider>
        <ContentRenderer content={content} />
      </EventProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($slug: String) {
    strapiPage(slug: { eq: $slug }) {
      __typename
      strapi_id
      updatedAt
      id
      slug
      title
      toc
      headerImage {
        caption
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content {
        ...Content
      }
    }
  }
`

export default Page
