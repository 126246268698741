import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const ContentImageCarousel: React.FC<any> = ({ content: { items } }) => {
  return (
    <Carousel>
      {items.map(({ caption, localFile }) => {
        const image = getImage(localFile)
        if (image) {
          return (
            <Carousel.Item>
              <GatsbyImage image={image} alt="" />
              <Carousel.Caption>
                <p>{caption}</p>
              </Carousel.Caption>
            </Carousel.Item>
          )
        }
      })}
    </Carousel>
  )
}

export default ContentImageCarousel
