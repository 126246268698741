import React from 'react'
import styled from 'styled-components'

import { formatDate } from '../shared/date-utils'

const DeadlineContainer = styled.div`
  padding: 12px;
`

export const Deadline: React.FC<any> = ({ deadline }) => (
  <DeadlineContainer>
    <b>{formatDate('dddd, MMMM Do YYYY, HH:mm:ss z')(deadline)}</b>
  </DeadlineContainer>
)
