import styled from 'styled-components'

const ReferencedRow = (width) => ({
  Row: styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 12px;
  `,
  Reference: styled.div`
    position: absolute;
    top: 0pt;
    width: ${width};
  `,
  Entry: styled.div`
    position: relative;
    left: ${width};
    width: calc(100% - ${width});
  `,
})

export default ReferencedRow
