import React, { useContext } from 'react'

import { EventContext } from '../event/event-provider'
import { EventRow } from '../event/event'
import { Heading } from '../layout/tableofcontent/heading'

const ContentEventList: React.FC<any> = ({ content }) => {
  const { getReference } = useContext(EventContext)
  const { eventCategories, headingLevel } = content
  return eventCategories.map((category) => {
    const {
      fields: { events },
      name,
    } = category
    return (
      <>
        <Heading level={headingLevel}>{name}</Heading>
        {events.map((event) => {
          const reference = getReference(event.slug)
          return <EventRow event={{ ...event, reference }} />
        })}
      </>
    )
  })
}

export default ContentEventList
