import React, { useState } from 'react'
import { slug } from 'github-slugger'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'

import { DetailsButton } from '../shared/details-box'
import MarkdownField from '../shared/markdown-field'
import EMail from '../shared/email'
import { formatDate } from '../shared/date-utils'

const MembersContainer = styled.ul``
const MemberContainer = styled.li`
  padding-left: 0px;

  .name {
    font-size: 150%;
  }

  .role {
    padding-bottom: 10px;
  }

  dt {
    width: 80px;
  }
`

const MemberPicture: React.FC<any> = ({ picture, name }) => {
  picture = picture && picture.localFile && getImage(picture.localFile)
  if (picture) {
    return (
      <GatsbyImage
        image={picture}
        style={{
          float: 'right',
          width: '25%',
          marginRight: '10px',
          maxWidth: '200px',
          maxHeight: '200px',
          borderRadius: '50%',
          paddingTop: '6px',
        }}
        alt={name}
      />
    )
  } else {
    return null
  }
}

const ScholarLinks: React.FC<any> = ({ scholar }) => {
  const ImageLink: React.FC<any> = ({ image, to }) => {
    return (
      <a href={to} style={{ color: 'rgba(0,0,0,0.55)' }}>
        <i className={`ai ai-${image} ai-2x me-2`}></i>
      </a>
    )
  }

  if (scholar) {
    const { googlescholar, inspires, orcid } = scholar
    return (
      <>
        {inspires ? (
          <ImageLink
            to={`https://inspirehep.net/authors/${inspires}`}
            image="inspire"
          />
        ) : null}
        {orcid ? (
          <ImageLink to={`https://orcid.org/${orcid}`} image="orcid" />
        ) : null}
        {googlescholar ? (
          <ImageLink
            to={`https://scholar.google.com/citations?user=${googlescholar}`}
            image="google-scholar"
          />
        ) : null}
      </>
    )
  } else {
    return null
  }
}

const FormerGroupMember: React.FC<any> = ({ member, children }) => {
  const [showDetails, setShowDetails] = useState(false)
  return (
    <>
      {children}
      <DetailsButton onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'hide details' : 'details'}
      </DetailsButton>
      <Collapse in={showDetails}>
        <div>
          <GroupMember member={member} />
        </div>
      </Collapse>
    </>
  )
}

const GroupMemberWrapper: React.FC<any> = ({ member }) => {
  const { name, startDate, endDate } = member
  const active = !(startDate && endDate)
  const format = formatDate('DD.MM.YY')
  if (active) {
    return <GroupMember member={member} />
  } else {
    return (
      <FormerGroupMember member={member}>
        {name} ({format(startDate)}-{format(endDate)})
      </FormerGroupMember>
    )
  }
}

const GroupMember: React.FC<any> = ({ member }) => {
  const {
    name,
    role,
    picture,
    email,
    website,
    office,
    description,
    scholar,
    startDate,
    endDate,
  } = member
  const format = formatDate('DD.MM.YY')
  const id = slug(name)
  return (
    <>
      <MemberPicture picture={picture} name={name} />
      <Row>
        <Col>
          <div className="name">{name}</div>
          <div className="role">{role}</div>
        </Col>
        <Col md="auto">
          <ScholarLinks scholar={scholar} />
        </Col>
      </Row>
      <Row>
        <dl>
          {startDate ? (
            <>
              <dt>Period:</dt>
              <dd>
                {format(startDate)} - {(endDate && format(endDate)) || 'now'}
              </dd>
            </>
          ) : null}
          <dt>E-Mail:</dt>
          {email.split('\n').map((email) => (
            <dd>
              <EMail>{email}</EMail>
            </dd>
          ))}
          {website ? (
            <>
              <dt>Website:</dt>{' '}
              <dd>
                <a href={`https://${website}`}>{website}</a>
              </dd>
            </>
          ) : null}
          {office ? (
            <>
              <dt>Office:</dt>
              <dd>{office}</dd>
            </>
          ) : null}
        </dl>
      </Row>
      {description ? <MarkdownField content={description} /> : null}
    </>
  )
}

const ContentGroupMember = ({ content: { members } }) => {
  return (
    <MembersContainer>
      {members.map((member) => {
        const { name } = member
        const id = slug(name)
        return (
          <MemberContainer key={id} id={id}>
            <GroupMemberWrapper member={member} />
          </MemberContainer>
        )
      })}
    </MembersContainer>
  )
}

export default ContentGroupMember
