import React from 'react'
import styled from 'styled-components'

import MarkdownField from '../shared/markdown-field'
import { formatDate } from '../shared/date-utils'

const JobTitle = styled.div`
  display: flex;
  margin-bottom: 0.5em;
  font-size: x-large;
  font-weight: bold;
`
const JobLocation = styled.div`
  display: flex;
`
const JobDescription = styled.div`
  display: flex;
  margin-top: 0.5rem;
`
const JobField = styled.span`
  background: #e9ecef;
  padding: 0px 5px;
  margin-right: 5px;
  border-radius: 3px;
  border: solid 1px;
`
const JobCategory = styled.span`
  padding: 0px 5px;
  margin-right: 5px;
`
const JobDeadline = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1em;
`
const Clock: React.FC = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="clock-circle"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    style={{ marginRight: '5px' }}
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"></path>
  </svg>
)

export const Announcement: React.FC<any> = ({ job }) => {
  const { title, inspires, position, deadline, description } = job
  return (
    <>
      <JobTitle>
        <a href={`https://inspirehep.net/jobs/${inspires}`}>{title}</a>
      </JobTitle>
      <JobLocation>Wroclaw U. • Europe</JobLocation>
      <JobDescription>
        <JobField>hep-th</JobField>
        <JobField>gr-qc</JobField>
        <JobField>math-ph</JobField>
        <JobCategory>{position}</JobCategory>
      </JobDescription>
      <JobDeadline>
        <Clock />
        <b>
          Deadline on {formatDate('dddd, MMMM Do YYYY, HH:mm:ss z')(deadline)}
        </b>
      </JobDeadline>
      {description ? <MarkdownField content={description} /> : null}
    </>
  )
}
