import React, { useState, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'

import { getApplication } from './rest-api'
import {
  ApplicantionDetails,
  ReferenceDetails,
  applicationDetails,
  refereeDetails,
} from './application-fragments'
import EMail from '../shared/email'
import { formatDate } from '../shared/date-utils'
import { Deadline } from './shared'
import { Heading } from '../layout/tableofcontent/heading'
import ApplicationDocuments from './application-documents'

export const ApplicationSummary: React.FC<any> = ({ id }) => {
  const [application, setApplication] = useState<any>(null)
  useEffect(() => {
    getApplication(id).then((application) => {
      setApplication(application)
    })
  }, [id])

  if (!application) {
    return <>Loading application...</>
  }
  if (application.error) {
    return <Alert>Error: {application.error}</Alert>
  }

  const { job, references } = application
  const { title, position, deadline } = job
  const phdPosition = position.toLowerCase() === 'phd'
  return (
    <>
      <h2>{title}</h2>
      <p>
        Thanks for submitting your application. It has been registered
        successfully. In the following you see a summary of your submitted data.
        A link to this page has be sent to you via email. Moreover, you can
        check if your references already uploaded their letters. All of them
        have received an email with a link to an upload form. Please keep in
        mind that we need their letters before the deadline{' '}
        <Deadline
          deadline={formatDate('dddd, MMMM Do YYYY, HH:mm:ss')(deadline)}
        />
        Thus, if they are not marked as received here after some time you should
        check with them. Once all letters arrived you will be noticed by an
        email that your application is complete. If you encounter any problems,
        please send an email to <EMail>falk.hassler@uwr.edu.pl</EMail>. To make
        it easier to find you application, send this mail from the email address
        you provided in the application form.
      </p>
      <Heading level={3}>General information</Heading>
      <ApplicantionDetails
        application={application}
        phdPosition={phdPosition}
        items={[
          ...applicationDetails.status,
          ...applicationDetails.applicant,
          ...applicationDetails.scientific,
          ...applicationDetails.received,
        ]}
      />
      <Heading level={3}>Reference Letters</Heading>
      <ReferenceDetails
        references={references}
        items={refereeDetails.general}
      />
      <Heading level={3}>Documents</Heading>
      <ApplicationDocuments
        application={application}
        phdPosition={phdPosition}
      />
    </>
  )
}
