import React from 'react'
import Badge from 'react-bootstrap/Badge'

import ReferenceRow from '../../styles/referenced-row'
import RenderInlineMath from '../shared/render-inline-math'
import FileLinks from '../shared/file-links'
import Cite from '../shared/markdown-field/citation'
import Event from './event'
import { DetailsButton, DetailsBox } from '../shared/details-box'

const { Row, Reference, Entry } = ReferenceRow('36px')

const TalkReference: React.FC<any> = ({ refs }) => (
  <Reference>
    {Array.isArray(refs) ? (
      <>
        {' '}
        {refs[0]}. <br /> ⋮ <br /> {refs[1]}.{' '}
      </>
    ) : (
      <> {refs}. </>
    )}
  </Reference>
)

const TalkRow: React.FC<any> = ({ talk }) => {
  const [abstractOpen, setAbstractOpen] = React.useState(false)
  const {
    refs,
    title,
    basedOn: basedOnRaw,
    files,
    blackboard,
    abstract,
    events,
  } = talk
  const basedOn = Array.isArray(basedOnRaw)
    ? basedOnRaw.map(({ key }) => key)
    : []
  const hasTalkFiles = Array.isArray(files) && files.length > 0 ? true : false
  return (
    <Row>
      <TalkReference refs={refs} />
      <Entry>
        <RenderInlineMath text={title} />
        {basedOn.length > 0 ? (
          <>
            , based on <Cite>{basedOn}</Cite>
          </>
        ) : null}
        {hasTalkFiles ? (
          <>
            , <FileLinks files={files} />
          </>
        ) : null}
        <DetailsButton
          details={abstract}
          onClick={() => setAbstractOpen(!abstractOpen)}
        >
          abstract
        </DetailsButton>
        {blackboard === true ? (
          <>
            {' '}
            <Badge pill bg="dark">
              blackboard
            </Badge>
          </>
        ) : null}
        <DetailsBox details={abstract} open={abstractOpen} />
        <p>
          {talk.events.length > 1 ? (
            <ul>
              {events.map((event) => (
                <li id={event.key}>
                  <Event event={event} />
                </li>
              ))}
            </ul>
          ) : (
            <div id={events[0].key}>
              <Event event={events[0]} />
            </div>
          )}
        </p>
      </Entry>
    </Row>
  )
}

export default TalkRow
