import React from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import 'react-calendar/dist/Calendar.css'

import { GeneralInput, TextInput } from '../shared/forms/inputs'
import { required, requiredEmail } from '../shared/forms/validators'
import PointSelector from '../shared/forms/pointselector'

export const ReferenceInput: React.FC<any> = ({ index }) => {
  const labels = ['First', 'Second', 'Third']
  const Num = labels[index]
  return (
    <>
      <TextInput
        name={`references[${index}].name`}
        label={`${Num} referee's name`}
        description="Please fill in title, first and last name."
        validate={required}
      />
      <TextInput
        name={`references[${index}].email`}
        label={`${Num} referee's email`}
        description="Please provide an official email address for the referee."
        validate={requiredEmail}
        type="email"
      />
    </>
  )
}

export const PointInputOrNA: React.FC<any> = ({
  name,
  label,
  description,
  min,
  max,
  steps,
  validate,
}) => {
  return (
    <GeneralInput
      name={name}
      label={label}
      description={description}
      validate={validate}
    >
      {(props) => {
        const points = props.input.value
        const na = points === -1
        const pointsSpecified =
          typeof points === 'number' && points >= 0 ? true : false
        return (
          <Row>
            {!na ? (
              <Form.Group as={Col}>
                <PointSelector
                  min={min}
                  max={max}
                  step={steps}
                  points={points >= 0 ? points : null}
                  changePoints={(points) => {
                    props.input.onChange(points)
                  }}
                />
              </Form.Group>
            ) : null}
            {!pointsSpecified ? (
              <Form.Group as={Col}>
                <Form.Check
                  label="can't or don't want to say"
                  checked={na}
                  onChange={() => {
                    if (na) {
                      props.input.onChange(null)
                    } else {
                      props.input.onChange(-1)
                    }
                  }}
                />
              </Form.Group>
            ) : null}
          </Row>
        )
      }}
    </GeneralInput>
  )
}
