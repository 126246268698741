import React from 'react'
import { graphql } from 'gatsby'

import ContentMarkdown from './content-markdown'
import ContentLocation from './content-location'
import ContentGroupMember from './content-group-member'
import ContentJob from './content-job'
import ContentPublicationList from './content-publication-list'
import ContentCourseList from './content-course-list'
import ContentVisitorList from './content-visitor-list'
import ContentTalkList from './content-talk-list'
import ContentEventList from './content-event-list'
import ContentNewsList from './content-news-list'
import ContentImageCarousel from './content-image-carousel'

export const componentsMap = {
  STRAPI__COMPONENT_PAGE_MARKDOWN: ContentMarkdown,
  STRAPI__COMPONENT_PAGE_LOCATION: ContentLocation,
  STRAPI__COMPONENT_PAGE_GROUP_MEMBER: ContentGroupMember,
  STRAPI__COMPONENT_PAGE_JOB: ContentJob,
  STRAPI__COMPONENT_PAGE_PUBLICATION_LIST: ContentPublicationList,
  STRAPI__COMPONENT_PAGE_COURSE_LIST: ContentCourseList,
  STRAPI__COMPONENT_PAGE_VISITOR_LIST: ContentVisitorList,
  STRAPI__COMPONENT_PAGE_TALK_LIST: ContentTalkList,
  STRAPI__COMPONENT_PAGE_EVENT_LIST: ContentEventList,
  STRAPI__COMPONENT_PAGE_NEWS_LIST: ContentNewsList,
  STRAPI__COMPONENT_PAGE_IMAGE_CAROUSEL: ContentImageCarousel,
}

const Content = ({ content }) => {
  const Component = componentsMap[content.__typename]

  if (!Component) {
    return null
  }

  return <Component content={content} />
}

const ContentRenderer = ({ content }) => {
  return (
    <>
      {content.map((content, idx) => (
        <Content key={`${idx}${content.__typename}`} content={content} />
      ))}
    </>
  )
}

/*
fragment Content on STRAPI__COMPONENT_PAGE_COURSE_LISTSTRAPI__COMPONENT_PAGE_EVENT_LISTSTRAPI__COMPONENT_PAGE_GROUP_MEMBERSTRAPI__COMPONENT_PAGE_JOBSTRAPI__COMPONENT_PAGE_LOCATIONSTRAPI__COMPONENT_PAGE_MARKDOWNSTRAPI__COMPONENT_PAGE_PUBLICATION_LISTSTRAPI__COMPONENT_PAGE_TALK_LISTSTRAPI__COMPONENT_PAGE_VISITOR_LISTUnion {

    ... on STRAPI__COMPONENT_PAGE_JOB {
      id
      job {
        title
        slug
        position
        inspires
        deadline
        description {
          data {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
*/

export const query = graphql`
  fragment Content on STRAPI__COMPONENT_PAGE_COURSE_LISTSTRAPI__COMPONENT_PAGE_EVENT_LISTSTRAPI__COMPONENT_PAGE_GROUP_MEMBERSTRAPI__COMPONENT_PAGE_IMAGE_CAROUSELSTRAPI__COMPONENT_PAGE_LOCATIONSTRAPI__COMPONENT_PAGE_MARKDOWNSTRAPI__COMPONENT_PAGE_NEWS_LISTSTRAPI__COMPONENT_PAGE_PUBLICATION_LISTSTRAPI__COMPONENT_PAGE_TALK_LISTSTRAPI__COMPONENT_PAGE_VISITOR_LISTUnion {
    __typename
    ... on STRAPI__COMPONENT_PAGE_MARKDOWN {
      id
      style
      body {
        data {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_PAGE_LOCATION {
      latitude
      longitude
      zoom
    }
    ... on STRAPI__COMPONENT_PAGE_GROUP_MEMBER {
      members {
        name
        role
        startDate
        endDate
        email
        website
        office
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        scholar {
          googlescholar
          inspires
          orcid
        }
        description {
          data {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_PAGE_PUBLICATION_LIST {
      headingLevel
      category {
        name
        publications {
          title
          author
          key
          date
          arXiv
          journal {
            doi
            name
            volume
            year
          }
          fields {
            reference
            inspire {
              citations
              record
            }
          }
          abstract {
            data {
              childMarkdownRemark {
                htmlAst
              }
            }
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_PAGE_COURSE_LIST {
      courses {
        name
        strapi_id
        semester {
          season
          year
        }
        slug
        usos
        description {
          data {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
        lectures {
          strapi_id
          name
          date
          files {
            caption
            localFile {
              name
              extension
              url
            }
          }
          comments {
            data {
              childMarkdownRemark {
                htmlAst
              }
            }
          }
          tutorial {
            strapi_id
            priorityDeadline
            date
            files {
              caption
              localFile {
                name
                extension
                url
              }
            }
            problems {
              strapi_id
              name
              number
              mandatory
              assignAll
              points
            }
          }
        }
      }
      headingLevel
    }
    ... on STRAPI__COMPONENT_PAGE_VISITOR_LIST {
      id
      visitors {
        name
        affiliation
        arrival
        departure
        host {
          name
        }
        description {
          data {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_PAGE_TALK_LIST {
      headingLevel
      showOtherParticipants
      eventCategories {
        name
        fields {
          events {
            id
          }
          talks {
            blackboard
            slug
            title
            abstract {
              data {
                childMarkdownRemark {
                  htmlAst
                }
              }
            }
            basedOn {
              key
            }
            files {
              caption
              localFile {
                name
                extension
                url
              }
            }
            events {
              canceled
              contribution
              endDate
              id
              locationName
              name
              online
              slug
              startDate
              type
              url
            }
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_PAGE_EVENT_LIST {
      headingLevel
      showParticipants
      eventCategories {
        name
        fields {
          events {
            canceled
            locationName
            name
            online
            slug
            startDate
            endDate
            type
            url
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_PAGE_NEWS_LIST {
      maxItems
    }
    ... on STRAPI__COMPONENT_PAGE_IMAGE_CAROUSEL {
      items {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        caption
      }
    }
  }
`

export default ContentRenderer
