import React from 'react'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

const ContentLocation = ({ content }) => {
  const { longitude, latitude, zoom } = content
  const position = [latitude, longitude]
  return (
    <MapContainer center={position} zoom={zoom} style={{ height: '400px' }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} />
    </MapContainer>
  )
}

export default ContentLocation
