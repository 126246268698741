import React, { useState } from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { DetailsButton, DetailsBox } from '../shared/details-box'
import { formatDateRange } from '../shared/date-utils'

const VisitorsContainer = styled.ul``
const VistorContainer = styled.li`
  padding-left: 0px;

  .head {
    span {
      font-size: 125%;
    }
    padding-bottom: 6px;
  }
`

const Visitor: React.FC<any> = ({ visitor }) => {
  const [showDetails, setShowDetails] = useState(false)
  const {
    name,
    affiliation,
    host: { name: hostName },
    arrival,
    departure,
    description,
  } = visitor
  return (
    <VistorContainer>
      <Row>
        <Col>
          <div className="head">
            <span>{name}</span>, {affiliation ? `${affiliation}, ` : null}
            {formatDateRange(arrival, departure)}, invited by {hostName}
            <DetailsButton
              details={description}
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? 'hide details' : 'details'}
            </DetailsButton>
          </div>
        </Col>
      </Row>
      <DetailsBox details={description} open={showDetails} />
    </VistorContainer>
  )
}

const ContentVisitorList = ({ content }) => {
  const { visitors } = content
  return (
    <VisitorsContainer>
      {visitors.map((visitor) => (
        <Visitor visitor={visitor} />
      ))}
    </VisitorsContainer>
  )
}

export default ContentVisitorList
