import React, { useMemo } from 'react'
import Hashids from 'hashids'
import { compile, serialize, stringify } from 'stylis'

import MarkdownField from '../shared/markdown-field'

const ContentMarkdown = ({ content }) => {
  const [className, style] = useMemo(() => {
    const hashids = new Hashids(
      '',
      0,
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    )
    const className = hashids.encodeHex(content.id.replaceAll('-', ''))
    let style: null | string = content.style
    if (style) {
      style = serialize(compile(`.${className}{${style}}`), stringify)
    }
    return [className, style]
  }, [content.style, content.id])
  return (
    <>
      {style ? (
        <>
          <style>{style}</style>
          <div className={className}>
            <MarkdownField content={content.body} />{' '}
          </div>
        </>
      ) : (
        <MarkdownField content={content.body} />
      )}
    </>
  )
}

export default ContentMarkdown
