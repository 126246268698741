import React from 'react'
import styled from 'styled-components'
import { Announcement } from '../job'

const JobContainer = styled.div`
  background: white;
  margin: 1em;
  padding: 1em;
`

const ContentJob = ({ content: { job } }) => {
  return (
    <JobContainer>
      <Announcement job={job} />
    </JobContainer>
  )
}

export default ContentJob
