import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { formatDate } from '../shared/date-utils'
import MarkdownField from '../shared/markdown-field'

const ContentNewsList: React.FC<any> = ({ content }) => {
  const newsList = useStaticQuery(graphql`
    query NewsDetailQuery {
      allStrapiFeed {
        nodes {
          id
          title
          date
          description {
            data {
              childMarkdownRemark {
                htmlAst
              }
            }
          }
        }
      }
    }
  `).allStrapiFeed.nodes
  return (
    <ul>
      {newsList.map(({ date, title, description }) => (
        <li>
          {formatDate('DD.MM.YY')(date)}: {title}{' '}
          <MarkdownField content={description} />
        </li>
      ))}
    </ul>
  )
}

export default ContentNewsList
