import React from 'react'
import { Link, navigate } from 'gatsby'
import { Form } from 'react-final-form'
import BootstrapForm from 'react-bootstrap/Form'

import { Heading } from '../layout/tableofcontent/heading'
import EMail from '../shared/email'
import { formatDate } from '../shared/date-utils'
import { Deadline } from './shared'
import {
  CaptchaInput,
  CheckboxInput,
  DateInput,
  FileInput,
  TextInput,
  SubmitButton,
} from '../shared/forms/inputs'
import { ReferenceInput } from './inputs'
import {
  required,
  requiredEmail,
  requiredAgree,
  optionalURL,
  requiredPDF,
  optionalPDF,
} from '../shared/forms/validators'
import { isBrowser } from './utils'
import { submitApplication } from './rest-api'

export const ApplicationForm: React.FC<any> = ({ job }) => {
  const { title, slug, deadline, open, position, strapi_id: jobId } = job
  if (!open) {
    return (
      <>
        <h2>{title}</h2>
        <p>
          Please note that the competition has been closed. No new applications
          will be accepted.
        </p>
      </>
    )
  }
  const phdPosition = position.toLowerCase() === 'phd'
  const highestDegree = phdPosition ? `Master's degree` : 'PhD'
  return (
    <>
      <h2>{title}</h2>
      <p>
        Thank you very much for your interest in joining our group. To apply,
        please fill the form below. Once you have completed all required fields,
        the submit button at the button of this page will become active and you
        can submit your application. If you encounter any problems with the form
        or have any other questions about the position which are not answered in
        the <Link to={`/jobs/${slug}`}>announcement</Link>, please send me an
        email to <EMail>falk.hassler@uwr.edu.pl</EMail>. Please also note that
        the deadline for this application is
        <Deadline
          deadline={formatDate('dddd, MMMM Do YYYY, HH:mm:ss z')(deadline)}
        />
        Your application can only receive full consideration if you have filled
        this form and we have received all your reference letters by then.
      </p>
      <Heading level={2}>Application Form</Heading>
      <Form
        onSubmit={async (values) => {
          const { slug } = await submitApplication({ ...values, job: jobId })
          if (slug) {
            navigate(`/jobs/application/${slug}`)
          }
        }}
        render={({ handleSubmit, invalid, submitting }) => (
          <BootstrapForm onSubmit={handleSubmit}>
            <Heading level={3}>General information</Heading>
            <TextInput
              name="firstName"
              label="First Name"
              description="Please fill in your first name."
              validate={required}
            />
            <TextInput
              name="lastName"
              label="Last Name"
              description="Please fill in your last name."
              validate={required}
            />
            <TextInput
              name="email"
              label="Email"
              description="Please enter your email here."
              validate={requiredEmail}
              type="email"
            />
            <TextInput
              name="papersLink"
              label={`Link to your publications and preprints (optional${
                phdPosition ? '' : ', but very much apprechiated'
              })`}
              description={
                <>
                  {phdPosition ? (
                    <>
                      Don't worry if you do not have any publications yet, just
                      leave this field empty. Otherwise,
                    </>
                  ) : null}
                  <a href="https://inspirehep.net/authors">INSPIRE</a> is
                  usually the best place for such a summary. Search for your
                  name and copy the link to your author's page. For example
                  Eduard Witten would put
                  "https://inspirehep.net/authors/983328" in this box.
                </>
              }
              validate={optionalURL}
            />
            <Heading level={3}>Education</Heading>
            <DateInput
              name="degreeDate"
              label={`${highestDegree} date (can be the expected date)`}
              description={`Please fill in the date of your ${highestDegree}.`}
              validate={required}
            />
            <TextInput
              name="degreeInstitution"
              label={`${highestDegree} university`}
              description={`Please fill in the name of the university where you obtained your ${highestDegree}.`}
              validate={required}
            />
            <Heading level={3}>References</Heading>
            {(phdPosition ? [0, 1] : [0, 1, 2]).map((index) => (
              <ReferenceInput key={index} index={index} />
            ))}
            <Heading level={3}>Application Material</Heading>
            <FileInput
              name="files.cv"
              label="Curriculum vitae"
              description="Please upload your cv in PDF format."
              validate={requiredPDF}
            />
            {phdPosition ? (
              <FileInput
                name="files.transcripts"
                label="Transcripts"
                description="Please upload your transcripts in PDF format."
                validate={requiredPDF}
              />
            ) : null}
            <FileInput
              name="files.statement"
              label={
                phdPosition ? 'Motivation letter' : 'Statement of research'
              }
              description={`Please upload your ${
                phdPosition ? 'letter' : 'statement'
              } in PDF format.`}
              validate={requiredPDF}
            />
            <FileInput
              name="files.coverLetter"
              label="Cover letter (optional)"
              description="You can optionally also upload a cover letter in PDF format."
              validate={optionalPDF}
            />
            <Heading level={3}>Statements</Heading>
            <CheckboxInput name="dataProcessing" validate={requiredAgree}>
              I hereby authorise you to process my personal data included in my
              job application solely for the purpose of the selection process in
              accordance with the article 7 of the Regulation of the European
              Parliament and Council (EU) 2016/679 of 27 April 2016 on the
              protection of natural persons with regard to the processing of
              personal data and on the free movement of such data, and repealing
              Directive 95/46/EC (General Data Protection Regulation) [OJ EU L.
              2016.119.1 of 4 May 2016].
            </CheckboxInput>
            {isBrowser() ? (
              <CaptchaInput sitekey="6LfuVnciAAAAAC2Clxc9f8xgujTb56jQK9B03KfY" />
            ) : null}
            <SubmitButton submitting={submitting} invalid={invalid} />
          </BootstrapForm>
        )}
      />
    </>
  )
}
