import React, { useContext } from 'react'

import { Heading } from '../layout/tableofcontent/heading'
import { EventContext } from '../event/event-provider'
import TalkRow from '../event/talk-row'

const ContentTalkList: React.FC<any> = ({ content }) => {
  const { eventCategories, headingLevel } = content
  const { getReference } = useContext(EventContext)
  return eventCategories
    .filter((category) => category.fields.talks.length > 0)
    .map((category) => {
      const {
        fields: { talks, events },
        name,
      } = category
      const visibleEvents = new Set(events.map(({ id }) => id))
      return (
        <>
          <Heading level={headingLevel}>{name}</Heading>
          {talks.map((talk) => {
            const { slug: talkSlug } = talk
            talk.events = talk.events.filter(({ id }) => visibleEvents.has(id))
            let refs = talk.events.map(({ slug: eventSlug }) =>
              getReference(`${talkSlug}-${eventSlug}`)
            )
            if (refs.length == 1) {
              refs = refs[0]
            } else {
              refs = [Math.min(...refs), Math.max(...refs)]
            }
            return <TalkRow talk={{ ...talk, refs }} />
          })}
        </>
      )
    })
}

export default ContentTalkList
