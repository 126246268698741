import React from 'react'
import styled from 'styled-components'

import RenderInlineMath from '../shared/render-inline-math'
import { DetailsButton, DetailsBox } from '../shared/details-box'
import { formatDate } from '../shared/date-utils'
import { Heading } from '../layout/tableofcontent/heading'
import ReferencedRow from '../../styles/referenced-row'

const Title: React.FC<any> = ({ entry }) => {
  if (entry.fields.inspire && entry.fields.inspire.record) {
    return (
      <a href={'http://inspirehep.net/record/' + entry.fields.inspire.record}>
        <RenderInlineMath text={entry.title} />
      </a>
    )
  } else if (entry.journal && entry.journal.doi) {
    return (
      <a href={'https://doi.org/' + entry.journal.doi}>
        <RenderInlineMath text={entry.title} />
      </a>
    )
  } else if (entry.file && entry.file.publicURL) {
    return (
      <a href={entry.file.publicURL}>
        <RenderInlineMath text={entry.title} />
      </a>
    )
  }
  return <RenderInlineMath text={entry.title} />
}

const Publication: React.FC<any> = ({ entry }) => {
  let publication: any[] = []
  if (entry.journal) {
    const { name, volume, year, pages } = entry.journal
    publication.push(', ' + name)
    if (volume) publication.push(<b> {volume}</b>)
    if (year) publication.push(` (${year})`)
    if (pages) publication.push(' ' + pages)
  } else if (entry.type !== 'thesis') {
    publication.push(', preprint')
    if (entry.date) publication.push(` ${formatDate('MMMM YYYY')(entry.date)}`)
  }
  return <>{publication}</>
}

const ArXiv: React.FC<any> = ({ arXiv }) => {
  if (arXiv) {
    return (
      <>
        , <a href={'http://arxiv.org/abs/' + arXiv}>arXiv:{arXiv}</a>
      </>
    )
  }
  return null
}

const CitationsFrame = styled.span`
  display: inline-block;
  color: #989898;
  letter-spacing: -0.03em;
  text-transform: uppercase;
`

const Citations: React.FC<any> = ({ citations }) => {
  if (citations && citations > 0) {
    return (
      <>
        , <CitationsFrame>Citations: {citations}</CitationsFrame>
      </>
    )
  }
  return null
}

const { Row: Paper, Reference, Entry } = ReferencedRow('44px')

export const BibEntry: React.FC<any> = ({ entry }) => {
  const [abstractOpen, setAbstractOpen] = React.useState(false)
  return (
    <Paper id={entry.key}>
      <Reference>[{entry.fields && entry.fields.reference}]</Reference>
      <Entry>
        {entry.author}: <Title entry={entry}></Title>
        {entry.note ? `, ${entry.note}` : null}
        <Publication entry={entry}></Publication>
        <ArXiv arXiv={entry.arXiv} />
        <DetailsButton
          details={entry.abstract}
          onClick={() => setAbstractOpen(!abstractOpen)}
        >
          abstract
        </DetailsButton>
        <Citations
          citations={entry.fields.inspire && entry.fields.inspire.citations}
        />
        <DetailsBox details={entry.abstract} open={abstractOpen} />
      </Entry>
    </Paper>
  )
}

const ContentPublicationList = ({ content: { headingLevel, category } }) => {
  return category.map(({ name, publications }) => {
    publications.sort((a, b) => (a.date > b.date ? -1 : 1))
    return (
      <>
        {headingLevel ? <Heading level={headingLevel}>{name}</Heading> : null}
        {publications.map((publication) => (
          <BibEntry entry={publication} />
        ))}
      </>
    )
  })
}

export default ContentPublicationList
